.textContainer {
  width: 50%;
  margin: auto;
  color: white;
}

@media (max-width: 320px) {
  .textContainer {
    width: 95%;
  }
}

@media (min-width: 320px) {
  .textContainer {
    width: 95%;
  }
}

@media (min-width: 480px) {
  .textContainer {
    width: 90%;
  }
}

@media (min-width: 768px) {
  .textContainer {
    width: 80%;
  }
}

@media (min-width: 1024px) {
  .textContainer {
    width: 80%;
  }
}

@media (min-width: 1200px) {
  .textContainer {
    width: 80%;
  }
}
