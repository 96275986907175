.iconDelete {
  opacity: 0.5;
  transition: opacity 0.25s ease-in !important;
}

.iconDrag {
  opacity: 0.5;
  transition: opacity 0.25s ease-in !important;
}

.iconDrag:hover {
  opacity: 1;
  cursor: move;
}

.buttonDrag:hover {
  cursor: move;
}
