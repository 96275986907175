.App {
  width: 100%;
  height: 100dvh;
  display: flex;
  place-content: center;
}

.AppLoading {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100dvh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.ChartContainer {
  display: flex;
  place-content: center;
  transition: width 0.5s ease-out, height 0.5s ease-out;
  align-items: flex-start;
  justify-content: flex-start;
}

.ChartContainer > div {
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-link {
  color: #000000;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Loader */

.loader {
  width: 40px;
  height: 40px;
  display: block;
  margin: 0;
  position: relative;
  color: #333;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  transform: scale(0.5) translate(0, 0);
  background-color: #333;
  border-radius: 50%;
  animation: animloader 1s infinite ease-in-out;
}
.loader::before {
  background-color: #1199ee;
  transform: scale(0.5) translate(-20px, -20px);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animloader {
  50% {
    transform: scale(1) translate(-50%, -50%);
  }
}

/* Loader ends */

/* Loader XL */

.loader-xl {
  width: 200px;
  height: 200px;
  display: block;
  margin: 0;
  position: relative;
  color: #333;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader-xl::after,
.loader-xl::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  transform: scale(0.5) translate(0, 0);
  background-color: #333;
  border-radius: 50%;
  animation: animloader 1s infinite ease-in-out;
}
.loader-xl::before {
  background-color: #1199ee;
  transform: scale(0.5) translate(-100px, -100px);
}

/* Loader XL ends */

.saveFileIcon {
  transition: transform 250ms;
}

.saveFileIcon:hover {
  transform: scale(1.2);
}

/* Recharts svg style */

.recharts-surface {
  height: auto;
  max-width: 100%;
  display: block;
}

/* Not recharts svg style */

.chart-surface {
  height: auto;
  max-width: 100%;
  display: block;
}
