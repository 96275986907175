@import url(https://fonts.googleapis.com/css?family=Roboto);

body {
  margin: 0;
  font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* TODO - HACK - google styles instead of link to stylesheet, which generated by google sign-in script ("google-client-script") */
#credential_picker_container {
  border: none;
  height: 330px;
  position: fixed;
  right: 20px;
  top: 20px;
  width: 391px;
  z-index: 9999;
}
#credential_picker_container iframe {
  border: none;
  width: 391px;
  height: 330px;
}
#g_a11y_announcement {
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}
.L5Fo6c-sM5MNb {
  border: 0;
  display: block;
  left: 0;
  position: relative;
  top: 0;
}
.L5Fo6c-bF1uUb {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.L5Fo6c-bF1uUb:focus {
  border: none;
  outline: none;
}
sentinel {
}
