a {
  color: rgba(0, 0, 0, 0.25);
}

.scrollControls {
  overflow-y: scroll;
  padding: 0 8px 48px 8px;
}

.scrollControls > .controlItem:first-child {
  margin-top: 8px !important;
}

.scrollControls > .uiGroupHeader:first-child {
  margin-top: 16px !important;
}

.scrollControls > .uiCollectionHeader:first-child {
  margin-top: 16px !important;
}
